<template>
  <menu-component v-if="isMenuDisplayed"/>
  <router-view/>
</template>

<script>
import MenuComponent from "@/components/commonComponents/MenuComponent";
export default {
  components: {MenuComponent},
  computed:{
    isMenuDisplayed() {
      const noMenuRoutes = ["login", "home"]
      return !noMenuRoutes.includes(this.$route.name)
    }
  }
}
</script>
