import {defineStore} from "pinia";

const ROLE = "role"
export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        role: localStorage.getItem(ROLE),
    }),

    getters: {
        getRolePath() {
            switch (this.role) {
                case "ROLE_ADMIN":
                    return "/admin";
                case "ROLE_DOCTOR":
                    return "/pneumologue";
                default:
                    return "/cmei";
            }
        },
        getHomePath() {
            return this.getRolePath + "/accueil"
        }
    },

    actions: {
        setRole(newRole) {
            localStorage.setItem(ROLE, newRole)
        },
        removeRole(){
            localStorage.removeItem(ROLE)
        }
    }
})
