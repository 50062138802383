<template>
  <nav class="banner">
    <div class="header">
      <router-link class="header-link" to="/login?logout" v-on:click="disconnect">
        <img alt="Déconnexion" class="img-header-basic img-header" src="@/assets/images/logout_green.png">
        <img alt="Déconnexion" class="img-header-hover img-header" src="@/assets/images/logout_green_light.png">
        <span>Déconnexion</span>
      </router-link>
    </div>
    <div class="header">
      <router-link class="header-link" to="/settings">
        <img alt="Paramètres" class="img-header-basic img-header" src="@/assets/images/settings_green.webp">
        <img alt="Paramètres" class="img-header-hover img-header" src="@/assets/images/settings_green_light.webp">
        <span>Paramètres</span>
      </router-link>
    </div>
    <div class="header">
      <router-link :to="homePath" class="header-link">
        <img alt="Accueil" class="img-header-basic img-header" src="@/assets/images/home-icon-silhouette_green.png">
        <img alt="Accueil" class="img-header-hover img-header"
             src="@/assets/images/home-icon-silhouette_green_light.png">
        <span>Accueil</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
import {useUserStore} from "../../store/user.ts"
import jwtService from "@/services/jwtService"

export default {
  name: "MenuComponent",
  setup() {
    const userStore = useUserStore();
    const disconnect = () => {
      jwtService.deleteToken()
      userStore.removeRole()
    }
    return {homePath: userStore.getHomePath, disconnect};
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  flex-direction: row-reverse;
  height: 112px;
}

.header {
  display: inline-block;
  text-align: center;
  margin: 18px;
}

.img-header {
  width: 64px;
}

span {
  display: block;
}

.header-link {
  text-decoration: none;
  color: var(--green);
}

.header-link:hover {
  color: var(--green-light);
}

.img-header-hover {
  display: none;
}

.header-link:hover .img-header-hover {
  display: inline-block;
}

.header-link:hover .img-header-basic {
  display: none;
}

@media (max-width: 640px) {
  nav {
    justify-content: space-evenly;
    height: auto;
  }

  .header {
    width: 100%;
  }

  .img-header {
    width: 48px;
  }

  span {
    display: none;
  }
}
</style>
