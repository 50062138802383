import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from "@/views/commonViews/LoginView"
import jwtService from "@/services/jwtService";

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Biodiv Alert - Connexion',
        },
        component: LoginView
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Biodiv Alert - Connexion',
        },
        component: LoginView
    },
    {
        path: '/admin/accueil',
        name: 'admin',
        meta: {
            title: 'Biodiv Alert - Accueil Admin',
        },
        // route level code-splitting
        // this generates a separate chunk (admin.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/adminViews/AdminHomeView.vue')
    },
    {
        path: '/admin/accueil-patients',
        name: 'adminPatients',
        meta: {
            title: 'Biodiv Alert - Gestion des patients - Admin',
        },
        component: () => import('@/views/adminViews/AdminPatientHomeView.vue')
    },
    {
        path: '/admin/accueil-comptes',
        name: 'adminAccounts',
        meta: {
            title: 'Biodiv Alert - Gestion des comptes - Admin',
        },
        component: () => import('@/views/adminViews/AdminAccountHomeView.vue')
    },
    {
        path: '/admin/ajouter-compte',
        name: 'adminAddAccount',
        meta: {
            title: 'Biodiv Alert - Ajout d\'un compte - Admin',
        },
        component: () => import('@/views/adminViews/AdminAddOrUpdateAccountView.vue')
    },
    {
        path: '/admin/modifier-compte/:id',
        props: true,
        name: 'adminUpdateAccount',
        meta: {
            title: 'Biodiv Alert - Gestion des comptes - Admin',
        },
        component: () => import('@/views/adminViews/AdminAddOrUpdateAccountView')
    },
    {
        path: '/admin/ajouter-patient',
        name: 'adminAddPatient',
        meta: {
            title: 'Biodiv Alert - Ajout d\'un patient - Admin',
        },
        component: () => import('@/views/adminViews/AdminAddOrUpdatePatientView')
    },
    {
        path: '/admin/modifier-patient/:id',
        props: true,
        name: 'adminUpdatePatient',
        meta: {
            title: 'Biodiv Alert - Gestion des patients - Admin',
        },
        component: () => import('@/views/adminViews/AdminAddOrUpdatePatientView')
    },
    {
        path: '/admin/ajouter-capteur',
        name: 'adminAddSensor',
        meta: {
            title: 'Digi Respi - Ajout d\'un capteur - Admin',
        },
        component: () => import('@/views/adminViews/AdminAddSensorView')
    },
    {
        path: '/admin/lier-capteur/:id',
        props: true,
        name: 'adminLinkSensor',
        meta: {
            title: 'Digi Respi - Gestion des capteurs - Admin',
        },
        component: () => import('@/views/adminViews/AdminLinkSensorView')
    },
    {
        path: '/admin/demander-visite-patient/:id',
        name: 'adminAskForVisit',
        props: true,
        meta: {
            title: 'Biodiv Alert - Demande de visite - Admin',
        },
        component: () => import('@/views/adminViews/AdminAskForVisitView')
    },
    {
        path: '/pneumologue/accueil',
        name: 'doctor',
        meta: {
            title: 'Biodiv Alert - Accueil Médecin',
        },
        component: () => import('@/views/doctorViews/DoctorHomeView.vue')
    },
    {
        path: '/pneumologue/patient/:id',
        name: 'doctorPatientFile',
        meta: {
            title: 'Biodiv Alert - Dossier patient - Médecin',
        },
        component: () => import('@/views/doctorViews/DoctorPatientFileView')
    },
    {
        path: '/cmei/accueil',
        name: 'medicalAdvisor',
        meta: {
            title: 'Biodiv Alert - Accueil CMEI',
        },
        component: () => import('@/views/medicalAdvisorViews/MedicalAdvisorHomeView')
    },
    {
        path: '/cmei/patient/:id',
        name: 'medicalAdvisorPatientFile',
        meta: {
            title: 'Biodiv Alert - Dossier patient - CMEI',
        },
        component: () => import('@/views/medicalAdvisorViews/MedicalAdvisorPatientFileView')
    },
    {
        path: '/cmei/ajouter-patient',
        name: 'medicalAdvisorAddPatient',
        meta: {
            title: 'Biodiv Alert - Ajout d\'un patient - CMEI',
        },
        component: () => import('@/views/adminViews/AdminAddOrUpdatePatientView')
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/commonViews/SettingsView')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== "login" && jwtService.hasTokenExpired()) {
        next({name: 'login'})
    } else {
        next()
    }
    document.title = to.meta.title;
})
export default router
