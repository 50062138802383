<template>
  <main>
    <h1>L'air intérieur chez vos patients</h1>
    <div v-if="badCredentials" class="error-message">
      Mail et/ou mot de passe invalides
    </div>
    <div v-if="isLoggedOut" class="error-message">
      Vous avez été déconnecté
    </div>
    <form v-on:submit.prevent="submitForm">
      <div class="label-input">
        <label for="mail"> Mail : </label>
        <input id="mail" v-model="form.mail" name="mail" type="text"/>
      </div>
      <div class="label-input">
        <label for="password"> Mot de passe : </label>
        <input id="password" v-model="form.password" name="password" type="password"/>
      </div>
      <div class="submitDiv">
        <input class="submit" type="submit" value="Se connecter"/>
      </div>
    </form>
    <div class="logos">
      <img alt="Biodiv'alert" src="@/assets/images/biodivalert_403_45.webp">
      <img alt="Biodiv'alert Médecin" class="qrcode" src="@/assets/images/qrcode_medecin_100.webp">
      <img alt="Biodiv'airsanté" src="@/assets/images/biodivairsante_400_35.webp">
    </div>
  </main>
</template>

<script>
import http from "../../services/http-common"
import {useUserStore} from "../../store/user.ts"
import {useRouter} from "vue-router"
import jwtService from "../../services/jwtService"
import {ref} from "vue";

export default {
  name: "LoginView",
  setup() {
    const router = useRouter();
    let form = {
      mail: '',
      password: ''
    }
    let badCredentials = ref(false)
    const userStore = useUserStore()
    const submitForm = () => {
      const url = "/login";
      const params = new URLSearchParams()
      params.append('username', form.mail)
      params.append('password', form.password)

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      http.post(url, params, config)
          .then((result) => {
            const accessToken = result.data["accessToken"]
            jwtService.writeToken(accessToken)
            const decoded = jwtService.decodeJwt(accessToken)
            userStore.setRole(decoded.roles[0])
            userStore.$reset()
            router.push(userStore.getHomePath)
          })
          .catch((error) => {
            badCredentials.value = true
            console.log(error)
          })
    }

    return {
      submitForm,
      form,
      badCredentials
    }
  },
  computed: {
    isLoggedOut() {
      return (this.$route.fullPath.endsWith("logout"))
    }
  }
}
</script>

<style lang="scss" scoped>

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 2em;
  margin: auto;
  border: var(--green-dark) solid 2px;
  border-radius: 5px;
  color: var(--green);
}

h1 {
  text-align: center;
}

img {
  display: block;
  max-height: 100px;
  max-width: available;
  width: auto;
  height: auto;
  margin: 1em;
}

.logos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: available;
}

.submitDiv {
  display: flex;
  border: none;
  justify-content: center;
}

@media (max-width: 640px) {
  main {
    padding-right: 0;
    padding-left: 0;
    height: 100%;
    width: 100%;
    border: none;
  }

  img {
    max-width: 240px;
  }
}

@media (min-width: 640px) {
  main {
    margin-top: 4rem;
  }
}
</style>
