import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from "pinia"
import jwtService from "@/services/jwtService";
import "./assets/css/main.scss"
import http from "@/services/http-common";
import $bus from './Event.js';


http.interceptors.request.use((request) => {
    const token = "Bearer " + jwtService.readToken()
    if (token !== null) {
        request.headers[jwtService.authorizationKey] = token
    }
    return request;
})

const app = createApp(App)
app.use(router).use(createPinia()).mount('#app')
app.config.globalProperties.$bus = $bus
