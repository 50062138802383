import * as jose from 'jose'

export default {

    authorizationKey: 'Authorization',

    decodeJwt(token) {
        return jose.decodeJwt(token)
    },

    writeToken(token) {
        localStorage.setItem(this.authorizationKey, token)
    },

    readToken() {
        return localStorage.getItem(this.authorizationKey)
    },

    deleteToken() {
        return localStorage.removeItem(this.authorizationKey)
    },

    hasTokenExpired() {
        const token = this.readToken()
        if (token == null) {
            return true
        }
        const decodedJwt = this.decodeJwt(token)
        const tokenEndTime = decodedJwt.exp
        const currentTime = Math.round(new Date().getTime() / 1000)

        return tokenEndTime < currentTime
    }
}
